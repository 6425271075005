import http from "./Http-Common";

class BrevoDataService {
  uploadcontacts(contact) {
    return http.post("/brevo/uploadcontact", { contact });
  }

  uploadawinpublishercsv(advertiserId, awinpublisher) {
    return http.post(`/brevo/awin/publishercsv/${advertiserId}`, {
      awinpublisher,
    });
  }

  uploadtraffic(network, trafficdata) {
    return http.post(`/brevo/traffic/trafficcsv/${network}`, {
      trafficdata,
    });
  }

  uploadpublisher(network, publisherscsvData) {
    return http.post(`/brevo/publisher/publishercsv/${network}`, {
      publisherscsvData,
    });
  }

  uploadsales(network, salescsvData) {
    return http.post(`/brevo/sales/salescsv/${network}`, {
      salescsvData,
    });
  }
}

export default new BrevoDataService();
